export const PRODUCTS = [
  {
    id: 1,
    productName: "iphone",
    price: 999,
  },
  { id: 2, productName: "cap", price: 99 },
  {
    id: 3,
    productName: "bed",
    price: 150,
  },

  {
    id: 4,
    productName: "oil",
    price: 9,
  },

  {
    id: 5,
    productName: "Alo",
    price: 4,
  },

  {
    id: 6,
    productName: "eggs",
    price: 2,
  },
];
